import { useEffect, useState } from 'react';

import { isEmpty, isEqual, map, set } from 'lodash';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { PermissionWrapper } from 'storybook-ui-components';

import { updateIAMRole } from '../../../../actions/iam';
import { updateUserAuthorizedProducts } from '../../../../actions/userManagement';
import { ReactComponent as ArrowDownIcon } from '../../../../assets/icons/arrowDown.svg';
import analyticsEvents from '../../../../constants/analyticsEvents';
import CustomConnect from '../../../../containers/HOC/CustomConnect';
import useGetUserPermissions from '../../../../Permissions/hooks';
import getPermission from '../../../../Permissions/mapping';
import { closeModal } from '../../../../reducers/modal';
import { authorizedProductOption, PERFORMANCE_METRIC_EVENTS } from '../../../../utils/constants';
import {
  useFormatAnalyticsData,
  useFormatPerformanceAnalyticsData
} from '../../../../utils/customHooks';
import { getAuthorizedProductOption } from '../../../../utils/helpers';
import CustomButton from '../../../Common/Buttons/CustomButton';
import UserCard from '../../../Common/Card/UserCard';
import CommonMultiSelelect from '../../../Common/Inputs/CommonMultiSelect';
import CommonSelect from '../../../Common/Inputs/CommonSelect';
import CustomModal from '../../../Common/Modal/CustomModal';
import RoleInfo from '../RoleInfo';
import generateRoleOptions from '../utils';

import './EditUser.scss';

function EditUser({ updateIAMRole, updateUserAuthorizedProducts }) {
  const dispatch = useDispatch();
  const rolesData = useSelector((state) => state.iam.rolesData);
  const userDetails = useSelector((state) => state.user.userDetails);
  const { role: authenticatedUserRole, clientId, email: userEmail } = userDetails;
  const modalData = useSelector((state) => state.modal);
  const { modalId, inputData } = modalData || {};
  const { name, email, role, authorizedProducts } = inputData || {};
  const isOpen = modalId === 'editUser';
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const formatAnalyticsData = useFormatAnalyticsData();
  const formatPerformanceAnalyticsData = useFormatPerformanceAnalyticsData();

  useEffect(() => {
    const authorizedProductOption = getAuthorizedProductOption(authorizedProducts || []);
    setSelectedProducts(authorizedProductOption);
  }, [modalId]);

  const handleSelect = (value) => {
    setSelectedRole(value);
  };
  const handleAuthorizedProduct = (value) => {
    setSelectedProducts(value);
  };

  const options = generateRoleOptions(rolesData, authenticatedUserRole);

  const resetFormFields = () => {
    setSelectedRole('');
    setSelectedProducts([]);
  };

  const closeHandler = (e, clearFields = false) => {
    if (clearFields) {
      resetFormFields();
    }
    dispatch(closeModal());
  };

  const updateRole = async () => {
    setIsLoading(true);
    const eventObj = {
      APIEndPoint: 'api/v1/user'
    };
    const selectedProductValue = map(selectedProducts, 'value');
    if (!isEmpty(selectedProducts) && !isEqual(selectedProductValue, authorizedProducts)) {
      const updateProdStartTime = performance.now();
      await updateUserAuthorizedProducts({
        email,
        selectedProducts: selectedProductValue,
        initialAuthorizedProducts: authorizedProducts
      });
      const processingTime = performance.now() - updateProdStartTime;
      set(eventObj, 'processingTime', processingTime);
      const eventName = PERFORMANCE_METRIC_EVENTS.USER_PAGE_EDIT_USER;
      formatPerformanceAnalyticsData(eventObj, eventName);
    }
    if (!isEmpty(selectedRole)) {
      const updateRoleStartTime = performance.now();
      set(eventObj, 'APIEndPoint', 'api/v1/iam/role');
      await updateIAMRole({
        email,
        role: selectedRole
      });
      const processingTime = performance.now() - updateRoleStartTime;
      set(eventObj, 'processingTime', processingTime);
      const eventName = PERFORMANCE_METRIC_EVENTS.USER_PAGE_EDIT_USER;
      formatPerformanceAnalyticsData(eventObj, eventName);
    }
    formatAnalyticsData(
      userEmail,
      clientId,
      analyticsEvents.DASHBBARD_USER_EDIT_USER_SUBMIT,
      'User'
    );
    setIsLoading(false);
    closeHandler(null, true);
  };

  return (
    <CustomModal id="change_role_modal" isOpen={isOpen} closeHandler={closeHandler}>
      <div id="change_role_modal__container">
        <div id="change_role_modal__header">
          <h1>Edit User</h1>
        </div>
        <div id="change_role_modal__body">
          <p>You are now editing the following user:</p>
          <div id="change_role_modal__body__user">
            <UserCard name={name} email={email} role={role} />
          </div>
          <div id="change_role_modal__body__role">
            <p>New Role</p>
            <CommonSelect
              id="change_role_modal__body__role__select"
              options={options}
              handleChange={handleSelect}
              label="Choose a new role"
              Icon={ArrowDownIcon}
              initValue={selectedRole}
              infoTextComponent={RoleInfo}
            />
          </div>
          <div id="change_role_modal__body__role">
            <p>New Authorized Products</p>
            <CommonMultiSelelect
              id="invite_users_modal__body__role__select"
              options={authorizedProductOption}
              label="Choose the authorized products"
              Icon={ArrowDownIcon}
              selectedProducts={selectedProducts}
              handleChange={handleAuthorizedProduct}
            />
          </div>
        </div>
        <div id="change_role_modal__footer">
          <CustomButton onClick={closeHandler} theme="white">
            Cancel
          </CustomButton>
          <CustomButton disabled={isLoading} onClick={updateRole} theme="purple">
            Submit
          </CustomButton>
        </div>
      </div>
    </CustomModal>
  );
}

const mapHooksToActions = {
  updateIAMRole,
  updateUserAuthorizedProducts
};

EditUser.propTypes = {
  updateIAMRole: PropTypes.func.isRequired,
  updateUserAuthorizedProducts: PropTypes.func.isRequired
};

export default PermissionWrapper(
  CustomConnect(EditUser, { mapHooksToActions }),
  useGetUserPermissions,
  getPermission('editUser')
);
