import { useEffect, useState } from 'react';

import { StyledEngineProvider } from '@mui/material/styles';
import { Spinner } from 'clm-components';
import PropTypes from 'prop-types';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { authenticateUser, getUser } from './actions/user';
import NotFound from './components/Common/NotFound';
import PrivateRoute from './components/Common/PrivateRoute';
import ErrorBoundary from './components/ErrorBoundary';
import CustomConnect from './containers/HOC/CustomConnect';
import UserManagement from './containers/UserManagement';

import './App.scss';

function App({ authenticateUser, getUser }) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initializeApp = async () => {
      setIsLoading(true);
      await authenticateUser();
      await getUser();
      setIsLoading(false);
    };
    initializeApp();
  }, []);

  if (isLoading) {
    return (
      <div className="App loader">
        <Spinner size="meduim" />
      </div>
    );
  }

  return (
    <StyledEngineProvider injectFirst>
      <ErrorBoundary>
        <div className="App">
          <BrowserRouter>
            <Routes>
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <UserManagement />
                  </PrivateRoute>
                }
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </div>
      </ErrorBoundary>
    </StyledEngineProvider>
  );
}

const mapHooksToActions = {
  authenticateUser,
  getUser
};

App.propTypes = {
  authenticateUser: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired
};

export default CustomConnect(App, { mapHooksToActions });
