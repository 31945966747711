import React from 'react';

import { ReactComponent as InfoIcon } from '../../../assets/icons/info.svg';
import './Modals.scss';

function RoleInfo() {
  return (
    <div className="role_option_cell_info" aria-hidden>
      <InfoIcon />
      <p>
        <a
          href="https://documentation.hyperverge.co/user_roles_and_permissions"
          target="_blank"
          rel="noreferrer">
          Learn more
        </a>{' '}
        about roles
      </p>
    </div>
  );
}

export default RoleInfo;
