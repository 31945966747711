import { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { authenticateUser } from '../../actions/user';
import CustomConnect from '../../containers/HOC/CustomConnect';

const RedirectPage = () => {
  useEffect(() => {
    window.location.replace(`${process.env.REACT_APP_APPLICATION_DASHBOARD_URL}`);
  }, []);
};

// eslint-disable-next-line no-shadow
function PrivateRoute({ children, authenticateUser }) {
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

  const { pathname } = useLocation();

  useEffect(() => {
    authenticateUser();
  }, [pathname]);

  if (isAuthenticated) {
    return children;
  }
  return <RedirectPage />;
}

PrivateRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  authenticateUser: PropTypes.func.isRequired
};

const mapHooksToActions = {
  authenticateUser
};

export default CustomConnect(PrivateRoute, { mapHooksToActions });
