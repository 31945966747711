import { get } from 'lodash';

import usersPagePermissions from './pages/usersPage';

const requiredPermissionsMapping = {
  ...usersPagePermissions
};

const getPermission = (id) => get(requiredPermissionsMapping, id, {});

export default getPermission;
