import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import iamReducer from './reducers/iam';
import modalReducer from './reducers/modal';
import onboardReducer from './reducers/onboard';
import toastReducer from './reducers/toast';
import userReducer from './reducers/user';
import userManagementReducer from './reducers/userManagement';

const combinedReducer = combineReducers({
  iam: iamReducer,
  modal: modalReducer,
  onboard: onboardReducer,
  toast: toastReducer,
  user: userReducer,
  userManagement: userManagementReducer
});

const persistConfig = { key: 'root', storage, blacklist: ['initialize'] };

const rootReducer = (state, action) => {
  let newState = state;
  if (action.type === 'RESET') {
    // check for action type
    newState = undefined;
  }
  return combinedReducer(newState, action);
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});
const persistor = persistStore(store);
// persistor.purge(); // Used to clear persist storage.

export { persistor };
export default store;
