import { createSlice } from '@reduxjs/toolkit';

export const iamSlice = createSlice({
  name: 'iam',
  initialState: {
    rolesData: {}
  },
  reducers: {
    updateRoles: (state, action) => {
      return { ...state, rolesData: action.payload };
    }
  }
});

// Action creators are generated for each case reducer function
export const { updateRoles } = iamSlice.actions;
export default iamSlice.reducer;
