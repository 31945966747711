import { createSlice } from '@reduxjs/toolkit';

export const onboardSlice = createSlice({
  name: 'onboard',
  initialState: {
    docToken: ''
  },
  reducers: {
    updateDocToken: (state, action) => {
      return { ...state, docToken: action.payload };
    }
  }
});

// Action creators are generated for each case reducer function
export const { updateDocToken } = onboardSlice.actions;
export default onboardSlice.reducer;
