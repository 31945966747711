import { get, isEmpty, map, pick } from 'lodash';

import listUsersAPI, {
  bulkOnboardUsersAPI,
  updateUserAPI,
  updateUserStatusAPI
} from '../api/userManagement';
import { userManagementAlertCodes } from '../constants/alertCodes';
import userListTableConfig from '../constants/config';
import {
  updateData,
  updateTableMeta,
  updateStatus,
  updateAuthorizedProduct
} from '../reducers/userManagement';
import { getAddAndRemoveAuthorizedProduct } from '../utils/helpers';

const { maxRowsPerPage } = userListTableConfig;

const listUsers =
  ({ filters, pageNumber, sortBy }) =>
  async ({ dispatch, showErrorToast }) => {
    const pageSize = maxRowsPerPage;
    try {
      const { searchValue } = filters || {};
      const res = await listUsersAPI({
        filters: {
          ...(searchValue ? { searchValue } : {})
        },
        pagination: {
          pageNumber,
          pageSize
        },
        sortBy
      });
      const userList = get(res, 'data.result.userList');
      const totalCount = get(res, 'data.result.totalCount', 0);
      if (pageNumber * pageSize > totalCount) {
        dispatch(
          updateTableMeta({
            page: 0
          })
        );
      }
      dispatch(updateData({ data: userList, count: totalCount }));
    } catch (error) {
      dispatch(updateData({}));
      showErrorToast({ error, message: userManagementAlertCodes.error.LIST_USERS });
    }
  };

export const bulkOnboardUsers =
  ({ userList, iamRole }) =>
  async ({ showToast, showErrorToast }) => {
    try {
      const res = await bulkOnboardUsersAPI({
        userList,
        iamRole
      });
      const errorUserList = get(res, 'data.result.errorUserList', []);
      if (!isEmpty(errorUserList)) {
        const partialError = map(errorUserList, (obj) =>
          pick(obj, ['email', 'error', 'statusCode'])
        );
        showToast({
          severity: 'info',
          message: userManagementAlertCodes.success.BULK_ONBOARD_SUCCESS_PARTIALLY
        });
        return { success: 'partial', partialError };
      }
      showToast({
        severity: 'success',
        message: userManagementAlertCodes.success.BULK_ONBOARD_SUCCESS
      });
      return { success: 'all' };
    } catch (error) {
      if (get(error, 'response.status') === 422) {
        showErrorToast({
          error,
          message: get(
            error,
            'response.data.error',
            userManagementAlertCodes.error.BULK_ONBOARD_INVALID_INPUT
          )
        });
      } else if (get(error, 'response.status') === 409) {
        showErrorToast({
          error,
          message: userManagementAlertCodes.error.BULK_ONBOARD_CONFLICT
        });
      } else {
        showErrorToast({
          error,
          message: userManagementAlertCodes.error.BULK_ONBOARD
        });
      }
      return { success: 'none' };
    }
  };

export const updateUserStatus =
  ({ email, isDisabled }) =>
  async ({ dispatch, showToast, showErrorToast }) => {
    try {
      const response = await updateUserStatusAPI({
        email,
        isDisabled
      });
      const status = get(response, 'data.result.status');
      dispatch(updateStatus({ email, status }));
      if (isDisabled) {
        showToast({
          severity: 'success',
          message: userManagementAlertCodes.success.DEACTIVATE_USER_SUCCESS
        });
      } else {
        showToast({
          severity: 'success',
          message: userManagementAlertCodes.success.REACTIVATE_USER_SUCCESS
        });
      }
    } catch (error) {
      if (get(error, 'response.status') === 401) {
        showErrorToast({
          error,
          message: userManagementAlertCodes.error.USER_UNAUTHORIZED_STATUS
        });
      } else {
        showErrorToast({ error, message: userManagementAlertCodes.error.USER_STATUS });
      }
    }
  };

export const updateUserAuthorizedProducts =
  ({ email, selectedProducts, initialAuthorizedProducts }) =>
  async ({ dispatch, showToast, showErrorToast }) => {
    try {
      const { addAuthorizedProducts, removeAuthorizedProducts } = getAddAndRemoveAuthorizedProduct(
        selectedProducts,
        initialAuthorizedProducts
      );
      await updateUserAPI({
        email,
        addAuthorizedProducts,
        removeAuthorizedProducts
      });
      dispatch(updateAuthorizedProduct({ email, authorizedProducts: selectedProducts }));
      showToast({
        severity: 'success',
        message: userManagementAlertCodes.success.UPDATE_USER
      });
    } catch (error) {
      if (get(error, 'response.status') === 401) {
        showErrorToast({
          error,
          message: userManagementAlertCodes.error.UPDATE_USER_UNAUTHORIZED
        });
      } else {
        showErrorToast({ error, message: userManagementAlertCodes.error.UPDATE_USER });
      }
    }
  };

export default listUsers;
