/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

import { ReactComponent as CarrotDownIcon } from '../../../assets/icons/carrotDown.svg';
import AddToolTipOnDisable from '../AddToolTipOnDisable';

import './Inputs.scss';

function CommonMultiSelect({ id, options, label, Icon, selectedProducts, handleChange }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const selectRef = useRef('');

  const handleClick = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const handleSelect = (value, itemLabel) => {
    if (selectedProducts.some((product) => product.value === value)) {
      handleChange((prev) => {
        return prev.filter((ele) => ele.value !== value);
      });
    } else {
      handleChange((product) => [...product, { label: itemLabel, value }]);
    }
  };

  const getLabel = () => {
    if (selectedProducts.length === 0) return label;
    return selectedProducts
      .map((selectedProduct) => selectedProduct.label)
      .sort()
      .join(' , ');
  };

  const handleMouseClick = (event) => {
    if (event.target !== selectRef.current && !selectRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  const clearAll = () => {
    handleChange(() => []);
  };

  const selectAll = () => {
    handleChange(() => options);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseClick);
    return () => {
      document.removeEventListener('mousedown', handleMouseClick);
    };
  }, []);

  return (
    <div id={id} className="common_select_container" ref={selectRef}>
      <div
        aria-hidden="true"
        onClick={handleClick}
        className={`${isMenuOpen && 'active'} common_select_button`}>
        {getLabel()}
        <Icon />
      </div>
      {isMenuOpen && (
        <div className="common_select_menu">
          <div className="common_select_count">
            <p>{selectedProducts.length} selected</p>
            {selectedProducts.length ? (
              <button type="button" onClick={clearAll}>
                clear all
              </button>
            ) : (
              <button type="button" onClick={selectAll}>
                select all
              </button>
            )}
          </div>
          {options.map(({ value, label: itemLabel }) => (
            <AddToolTipOnDisable key={value}>
              <label key={value} aria-hidden>
                {itemLabel}
                <input
                  type="checkbox"
                  onClick={() => handleSelect(value, itemLabel)}
                  checked={getLabel().includes(itemLabel)}
                />
              </label>
            </AddToolTipOnDisable>
          ))}
        </div>
      )}
    </div>
  );
}

CommonMultiSelect.defaultProps = {
  id: '',
  label: 'Select',
  Icon: CarrotDownIcon,
  selectedProducts: [],
  handleChange: () => {}
};

CommonMultiSelect.propTypes = {
  id: PropTypes.string,
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  Icon: PropTypes.element,
  selectedProducts: PropTypes.array,
  handleChange: PropTypes.func
};

export default CommonMultiSelect;
