import { get } from 'lodash';

import fetchDocTokenAPI from '../api/onboard';
import { onboardAlertCodes } from '../constants/alertCodes';
import { updateDocToken } from '../reducers/onboard';

const fetchDocToken =
  () =>
  async ({ dispatch, showErrorToast }) => {
    try {
      const res = await fetchDocTokenAPI();
      const docToken = get(res, 'data.result', '');
      dispatch(updateDocToken(docToken));
    } catch (error) {
      showErrorToast({ error, message: onboardAlertCodes.error.DOC_TOKEN });
    }
  };

export default fetchDocToken;
