import PropTypes from 'prop-types';

import UserFilters from './UserFilters';
import UserListTable from './UserListTable/UserListTable';

function Body({ isLoading, handlePageChange }) {
  return (
    <div id="user_management__body__container">
      <UserFilters />
      <UserListTable isLoading={isLoading} handlePageChange={handlePageChange} />
    </div>
  );
}

Body.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  handlePageChange: PropTypes.func.isRequired
};

export default Body;
