import { createSlice } from '@reduxjs/toolkit';

const defaultMessage = 'Something went wrong';
const defaultSeverity = 'error';

const initialState = {
  show: false,
  severity: defaultSeverity,
  message: ''
};
export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    showToast: (state, action) => {
      const { severity, message } = action.payload;
      return {
        ...state,
        show: true,
        severity: severity || defaultSeverity,
        message: message || defaultMessage
      };
    },
    closeToast: () => {
      return initialState;
    }
  }
});

// Action creators are generated for each case reducer function
export const { showToast, closeToast } = toastSlice.actions;
export default toastSlice.reducer;
