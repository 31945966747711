import React from 'react';

import PropTypes from 'prop-types';

import CustomButton from '../../../Common/Buttons/CustomButton';

function Footer({
  isFormEmpty,
  isLoading,
  didFailPartially,
  closeHandler,
  resetPartialFail,
  handleConfirmClick
}) {
  if (didFailPartially) {
    return (
      <div id="invite_users_modal__footer">
        <CustomButton onClick={closeHandler} theme="white">
          Cancel
        </CustomButton>
        <CustomButton onClick={resetPartialFail} theme="purple">
          Go back
        </CustomButton>
      </div>
    );
  }

  return (
    <div id="invite_users_modal__footer">
      <CustomButton onClick={closeHandler} theme="white">
        Cancel
      </CustomButton>
      <CustomButton disabled={isFormEmpty || isLoading} onClick={handleConfirmClick} theme="purple">
        Send Invite
      </CustomButton>
    </div>
  );
}

Footer.propTypes = {
  isFormEmpty: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  didFailPartially: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired,
  resetPartialFail: PropTypes.func.isRequired,
  handleConfirmClick: PropTypes.func.isRequired
};

export default Footer;
