import PropTypes from 'prop-types';
import Avatar from 'react-avatar';

import RoleCell from '../../UserManagement/Body/UserListTable/Cells/RoleCell';

import './Card.scss';

function UserCard({ id, name, email, role, status }) {
  let title = name;
  let description = (
    <>
      {email}
      {role ? (
        <>
          {'    .    '}
          <RoleCell iamRole={role} />
        </>
      ) : (
        ''
      )}
    </>
  );

  if (status === 'user_invited') {
    title = email;
    description = 'Invitation Sent';
  }

  return (
    <div id={id} className="user_card__container">
      <Avatar className="user_card__image" name={name} size="30" round />
      <div data-hj-suppress className="user_card__data">
        <span className="user_card__data__title">{title}</span>
        <span className="user_card__data__description">{description}</span>
      </div>
    </div>
  );
}

UserCard.defaultProps = {
  id: '',
  role: null,
  status: null
};

UserCard.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  role: PropTypes.string,
  status: PropTypes.string
};

export default UserCard;
