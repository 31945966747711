import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    isAuthenticated: false,
    userDetails: {
      permissions: [],
      role: '',
      email: '',
      name: '',
      clientId: '',
      userLocation: {}
    }
  },
  reducers: {
    updateUserInfo: (state, action) => {
      const { permissions, role, email, name, clientId } = action.payload;
      return { ...state, userDetails: { permissions, role, email, name, clientId } };
    },

    updateIsAuthenticated: (state, action) => {
      return { ...state, isAuthenticated: action.payload };
    },

    updateUserLocation: (state, action) => {
      return { ...state, userLocation: action.payload };
    }
  }
});

// Action creators are generated for each case reducer function
export const { updateUserInfo, updateIsAuthenticated, updateUserLocation } = userSlice.actions;
export default userSlice.reducer;
