import * as Sentry from '@sentry/react';
import { useDispatch } from 'react-redux';

import { closeToast, showToast } from '../reducers/toast';

export const useCustomToast = () => {
  const dispatch = useDispatch();

  const showCustomToast = ({ severity, message }) => {
    dispatch(showToast({ severity, message }));
    setTimeout(() => {
      dispatch(closeToast());
    }, 2000);
  };
  return showCustomToast;
};

export const useErrorToast = () => {
  const showCustomToast = useCustomToast();
  const showErrorToast = async ({ error, message }) => {
    if (error && error.response && error.response.status !== 401) {
      Sentry.captureException(error);
    }
    showCustomToast({ severity: 'error', message });
  };
  return showErrorToast;
};
