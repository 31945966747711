import { get } from 'lodash';
import PropTypes from 'prop-types';

const userStatusMap = {
  user_active: 'active',
  user_invited: 'invited',
  user_disabled: 'deactivated'
};

function StatusCell({ userStatus }) {
  const status = get(userStatusMap, userStatus, userStatus);

  if (!userStatus) return '';

  return <span className={`status_cell ${status}`}>{status}</span>;
}

StatusCell.propTypes = {
  userStatus: PropTypes.string.isRequired
};

export default StatusCell;
