import { get } from 'lodash';

import { listRolesAPI, updateIAMRoleAPI } from '../api/iam';
import { iamAlertCodes } from '../constants/alertCodes';
import { updateRoles } from '../reducers/iam';
import { updateRole } from '../reducers/userManagement';

export const listRoles =
  () =>
  async ({ dispatch, showErrorToast }) => {
    try {
      const res = await listRolesAPI();
      const roles = get(res, 'data.result', {});
      dispatch(updateRoles(roles));
    } catch (error) {
      dispatch(updateRoles({}));
      showErrorToast({ error, message: iamAlertCodes.error.LIST_ROLES });
    }
  };

export const updateIAMRole =
  ({ email, role }) =>
  async ({ dispatch, showToast, showErrorToast }) => {
    try {
      await updateIAMRoleAPI({
        email,
        iamRole: role
      });
      dispatch(updateRole({ email, role }));
      showToast({ severity: 'success', message: iamAlertCodes.success.UPDATE_ROLE });
    } catch (error) {
      if (get(error, 'response.status') === 401) {
        showErrorToast({ error, message: iamAlertCodes.error.UPDATE_ROLE_UNAUTHORIZED });
      } else {
        showErrorToast({ error, message: iamAlertCodes.error.UPDATE_ROLE });
      }
    }
  };
