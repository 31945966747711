/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import moment from 'moment';

import Options from './Cells/Options';
import RoleCell from './Cells/RoleCell';
import StatusCell from './Cells/StatusCell';
import SortHeader from './SortHeader';
import UserCard from '../../../Common/Card/UserCard';

const formatDate = (date) => {
  if (!date) return '';
  const momentObj = moment(date);
  let formatedLastSeen;
  const currentDayDate = moment();
  const previousDayDate = moment().subtract(1, 'days');
  if (momentObj.isSame(currentDayDate, 'day')) {
    formatedLastSeen = 'Today';
  } else if (momentObj.isSame(previousDayDate, 'day')) {
    formatedLastSeen = 'Yesterday';
  } else {
    formatedLastSeen = momentObj.format('MMM DD, YYYY');
  }
  return formatedLastSeen;
};

export const tableHeaders = {
  user: {
    label: 'User'
  },
  role: {
    label: 'Role'
  },
  lastSeen: {
    label: 'Last Seen'
  },
  status: {
    label: 'Status'
  },
  joinedOn: {
    componentRenderer: () => <SortHeader text="Joined On" sortKey="_id" />
  },
  options: {
    label: '',
    width: '5%'
  }
};

export const genTableData = (data) =>
  data.map(
    ({ name, lastSignInTime, creationTime, email, iamRole, status, authorizedProducts }) => ({
      user: {
        componentRenderer: (props) => (
          <UserCard name={name} email={email} status={status} {...props} />
        )
      },

      role: {
        componentRenderer: (props) => <RoleCell iamRole={iamRole} {...props} />
      },
      lastSeen: {
        label: formatDate(lastSignInTime)
      },
      status: {
        componentRenderer: (props) => <StatusCell userStatus={status} {...props} />
      },
      joinedOn: {
        label: formatDate(creationTime)
      },
      options: {
        componentRenderer: (props) => (
          <Options
            name={name}
            email={email}
            role={iamRole}
            status={status}
            authorizedProducts={authorizedProducts || []}
            {...props}
          />
        )
      }
    })
  );
