import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import SortIcon from '../../../../assets/icons/sorting.svg';
import { updateTableMeta } from '../../../../reducers/userManagement';

const sortSwitch = {
  asc: 'desc',
  desc: 'asc'
};

function SortHeader({ text, sortKey }) {
  const dispatch = useDispatch();
  const tableSort = useSelector((state) => state.userManagement.tableMeta.sortBy);

  const handleSort = () => {
    const currentSortVal = get(tableSort, sortKey);
    dispatch(
      updateTableMeta({
        sortBy: {
          ...tableSort,
          [sortKey]: get(sortSwitch, currentSortVal, currentSortVal)
        }
      })
    );
  };

  return (
    <div className="sort_header">
      {text}
      <img src={SortIcon} alt="" onClick={handleSort} aria-hidden />
    </div>
  );
}

SortHeader.propTypes = {
  text: PropTypes.string.isRequired,
  sortKey: PropTypes.string.isRequired
};

export default SortHeader;
