import { useEffect } from 'react';

import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Drawer,
  getDrawerApplicationPagePath,
  getHiddenAndDisabledDrawerKeysFromPermissions
} from 'storybook-ui-components';

import fetchDocToken from '../../actions/onboard';
import { logout } from '../../actions/user';
import useGetUserPermissions from '../../Permissions/hooks';
import navMapping from '../../utils/navMapping';
import CustomConnect from '../HOC/CustomConnect';

function DrawerWrapper({ fetchDocToken, logout }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { navKey, subNavKey } = get(navMapping, pathname, {});

  const docToken = useSelector((state) => state.onboard.docToken);

  const { allowedPermissions, disallowedPermissions } = useGetUserPermissions();

  const applicationsTab = getDrawerApplicationPagePath(
    allowedPermissions,
    disallowedPermissions,
    pathname
  );

  const { hiddenNavKeys, disabledNavKeys } =
    getHiddenAndDisabledDrawerKeysFromPermissions(disallowedPermissions);

  useEffect(() => {
    fetchDocToken();
  }, []);

  const drawerFunctions = {
    home: () => {
      window.location.replace(`${process.env.REACT_APP_APPLICATION_DASHBOARD_URL}/home`);
    },
    applications: () => {
      window.location.replace(
        `${process.env.REACT_APP_APPLICATION_DASHBOARD_URL}${applicationsTab}`
      );
    },
    workflows: () => {
      window.location.replace(process.env.REACT_APP_WORKFLOW_APP_URL);
    },
    credentials: () => {
      window.location.replace(`${process.env.REACT_APP_APPLICATION_DASHBOARD_URL}/credentials`);
    },
    usage: () => {
      window.location.replace(`${process.env.REACT_APP_APPLICATION_DASHBOARD_URL}/usage`);
    },
    documentation: () =>
      window.open(`https://developer.hyperverge.co/global/kyc/-?jwt=${docToken}`),
    users: () => {
      navigate('/');
    },
    businessMetrics: () => window.location.replace(process.env.REACT_APP_ANALYTICS_APP_URL),
    stepwiseAnalytics: () =>
      window.location.replace(`${process.env.REACT_APP_ANALYTICS_APP_URL}/stepwise`),
    logout
  };

  return (
    <Drawer
      onClickFunctions={drawerFunctions}
      activeNav={navKey}
      activeSubNav={subNavKey}
      hiddenNavKeys={hiddenNavKeys}
      disabledNavKeys={disabledNavKeys}
    />
  );
}

const mapHooksToActions = {
  fetchDocToken,
  logout
};

DrawerWrapper.propTypes = {
  fetchDocToken: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired
};

export default CustomConnect(DrawerWrapper, { mapHooksToActions });
