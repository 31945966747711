import { useState, useEffect } from 'react';

import { set } from 'lodash';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { PermissionWrapper } from 'storybook-ui-components';

import CustomConnect from './HOC/CustomConnect';
import MainLayout from './Layout/MainLayout';
import { listRoles } from '../actions/iam';
import listUsers from '../actions/userManagement';
import Body from '../components/UserManagement/Body/Body';
import Header from '../components/UserManagement/Header/Header';
import EditUser from '../components/UserManagement/Modals/EditUser/EditUser';
import InviteUsers from '../components/UserManagement/Modals/InviteUsers/InviteUsers';
import useGetUserPermissions from '../Permissions/hooks';
import getPermission from '../Permissions/mapping';
import { updateTableMeta } from '../reducers/userManagement';
import { PERFORMANCE_METRIC_EVENTS } from '../utils/constants';
import './UserManagement.scss';
import { useFormatPerformanceAnalyticsData } from '../utils/customHooks';

function UserManagement({ listUsers, listRoles }) {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.userManagement.filters);
  const tableMeta = useSelector((state) => state.userManagement.tableMeta);
  const formatPerformanceAnalyticsData = useFormatPerformanceAnalyticsData();
  const { page, sortBy } = tableMeta || {};

  const listUserReqData = {
    filters,
    pageNumber: page,
    sortBy
  };

  const handlePageChange = async (event, newPage) => {
    dispatch(updateTableMeta({ page: newPage - 1 }));
  };

  useEffect(() => {
    const listUsersStartTime = performance.now();
    const eventObj = {
      APIEndPoint: 'api/v1/user/list',
      metadata: {
        filters,
        page
      }
    };
    async function listUsersAsync() {
      setIsLoading(true);
      await listUsers(listUserReqData);
      setIsLoading(false);
    }
    listUsersAsync();
    const processingTime = performance.now() - listUsersStartTime;
    set(eventObj, 'processingTime', processingTime);
    const eventName = PERFORMANCE_METRIC_EVENTS.USER_PAGE_LIST_USERS;
    formatPerformanceAnalyticsData(eventObj, eventName);
  }, [page, sortBy, filters]);

  useEffect(() => {
    const fetchRoleStartTime = performance.now();
    const eventObj = {
      APIEndPoint: 'api/v1/iam'
    };
    listRoles();
    const processingTime = performance.now() - fetchRoleStartTime;
    set(eventObj, 'processingTime', processingTime);
    const eventName = PERFORMANCE_METRIC_EVENTS.USER_PAGE_INIT_FETCH;
    formatPerformanceAnalyticsData(eventObj, eventName);
  }, []);

  return (
    <MainLayout>
      <div id="main__container__body__generic">
        <EditUser />
        <InviteUsers />
        <Header />
        <Body isLoading={isLoading} handlePageChange={handlePageChange} />
      </div>
    </MainLayout>
  );
}

const mapHooksToActions = {
  listUsers,
  listRoles
};

UserManagement.propTypes = {
  listUsers: PropTypes.func.isRequired,
  listRoles: PropTypes.func.isRequired
};

export default PermissionWrapper(
  CustomConnect(UserManagement, { mapHooksToActions }),
  useGetUserPermissions,
  getPermission('usersPage')
);
