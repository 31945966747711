import PropTypes from 'prop-types';

import options from '../../../../../assets/icons/options.svg';

function OptionsButton({ isHoveredOn, handleClick }) {
  return (
    <div
      type="button"
      id="options_button"
      onClick={handleClick}
      className={`options_button ${!isHoveredOn && 'disabled'}`}
      aria-hidden>
      <img src={options} alt="" />
    </div>
  );
}

OptionsButton.propTypes = {
  isHoveredOn: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired
};

export default OptionsButton;
