/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

import { ReactComponent as CarrotDownIcon } from '../../../assets/icons/carrotDown.svg';
import { ReactComponent as InfoIcon } from '../../../assets/icons/info.svg';
import AddToolTipOnDisable from '../AddToolTipOnDisable';

import './Inputs.scss';

function CommonSelect({
  id,
  options,
  label,
  isSelected,
  handleChange,
  Icon,
  initValue,
  displayComponentRenderer,
  isDropdown,
  infoTextComponent
}) {
  const [selectedLabel, setSelectedLabel] = useState(label);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const selectRef = useRef('');

  const handleClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClose = () => {
    setIsMenuOpen(false);
  };

  const handleSelect = (value, itemLabel, isDisabled) => {
    if (isDisabled) return;
    handleChange(value);
    if (!isDropdown) setSelectedLabel(itemLabel);
    handleClose();
  };

  useEffect(() => {
    if (initValue) {
      const { label: initLabel } = options.find(({ value }) => value === initValue);
      handleSelect(initValue, initLabel);
    }
  }, []);

  const handleMouseClick = (event) => {
    if (event.target !== selectRef.current && !selectRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseClick);
    return () => {
      document.removeEventListener('mousedown', handleMouseClick);
    };
  }, []);

  return (
    <div id={id} className="common_select_container" ref={selectRef}>
      <div
        aria-hidden="true"
        onClick={handleClick}
        className={`${isMenuOpen && 'active'}  ${isSelected && 'selected'} common_select_button`}>
        {displayComponentRenderer ? displayComponentRenderer(selectedLabel) : selectedLabel}
        <Icon />
      </div>
      {isMenuOpen && (
        <div className="common_select_menu">
          {options.map(({ value, label: itemLabel, componentRenderer, disable }) => (
            <AddToolTipOnDisable key={value} isDisabled={disable}>
              <div
                key={value}
                className={`${disable ? 'disable' : ''}`}
                onClick={() => handleSelect(value, itemLabel, disable)}
                aria-hidden>
                {componentRenderer ? componentRenderer() : itemLabel}
              </div>
            </AddToolTipOnDisable>
          ))}
          {infoTextComponent()}
        </div>
      )}
    </div>
  );
}

CommonSelect.defaultProps = {
  id: '',
  label: 'Select',
  isSelected: false,
  Icon: CarrotDownIcon,
  initValue: null,
  displayComponentRenderer: null,
  isDropdown: false,
  infoTextComponent: () => {}
};

CommonSelect.propTypes = {
  id: PropTypes.string,
  options: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  isSelected: PropTypes.bool,
  Icon: PropTypes.element,
  initValue: PropTypes.string,
  displayComponentRenderer: PropTypes.func,
  isDropdown: PropTypes.bool,
  infoTextComponent: PropTypes.element
};

export default CommonSelect;
