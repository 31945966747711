import axios from 'axios';

const axiosRequest = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/api/v1/user`,
  withCredentials: true
});

const listUsersAPI = (data) => axiosRequest.post('/list', data);

export const bulkOnboardUsersAPI = (data) => axiosRequest.post('/bulk/onboard', data);

export const updateUserStatusAPI = (data) => axiosRequest.put('/status', data);

export const updateUserAPI = (data) => axiosRequest.put('/', data);

export default listUsersAPI;
