import { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

import './Modal.scss';

function CustomModal({ id, children, isOpen, closeHandler, backdropStyles, modalStyles }) {
  const modalRef = useRef(null);

  const handleMouseClick = (event) => {
    if (
      modalRef.current &&
      event.target !== modalRef.current &&
      !modalRef.current.contains(event.target)
    ) {
      closeHandler();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseClick);
    return () => {
      document.removeEventListener('mousedown', handleMouseClick);
    };
  }, []);

  if (!isOpen) return '';

  return (
    <div id={id} style={backdropStyles} className="custom_modal__container">
      <div className="custom_modal" style={modalStyles} ref={modalRef}>
        {children}
      </div>
    </div>
  );
}

CustomModal.defaultProps = {
  id: '',
  children: '',
  backdropStyles: {},
  modalStyles: {}
};

CustomModal.propTypes = {
  id: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  backdropStyles: PropTypes.string,
  modalStyles: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired
};

export default CustomModal;
