import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { updateFilters } from '../../../reducers/userManagement';
import CommonSearchBar from '../../Common/Inputs/CommonSearchBar';

function UserFilters() {
  const dispatch = useDispatch();
  const totalCount = useSelector((state) => state.userManagement.dataCount);
  const searchValue = useSelector((state) => state.userManagement.filters.searchValue);

  const handleSearch = (value) => {
    dispatch(updateFilters({ searchValue: value }));
  };

  return (
    <div id="user_management__filter__container">
      <div id="user_management__filter__count">{totalCount} Users</div>
      <CommonSearchBar
        placeholder="Name or Email ID"
        handleSearch={handleSearch}
        initValue={searchValue}
      />
    </div>
  );
}

export default UserFilters;
