const usersPageComponentPermissions = {
  requiredPermissions: {
    viewPermissions: ['usersPage.view'],
    interactPermissions: ['usersPage.interact']
  },
  redirect: () => {
    window.location.href = `${process.env.REACT_APP_APPLICATION_DASHBOARD_URL}/home`;
  }
};

const editUserPermissions = {
  requiredPermissions: {
    viewPermissions: ['usersPage.editUser.view'],
    interactPermissions: ['usersPage.editUser.interact']
  }
};

const changeUserStatusPermissions = {
  requiredPermissions: {
    viewPermissions: ['usersPage.changeUserStatus.view'],
    interactPermissions: ['usersPage.changeUserStatus.interact']
  }
};

const bulkOnboardPermissions = {
  requiredPermissions: {
    viewPermissions: ['usersPage.bulkOnboard.view'],
    interactPermissions: ['usersPage.bulkOnboard.interact']
  }
};

const usersPagePermissions = {
  usersPage: usersPageComponentPermissions,
  editUser: editUserPermissions,
  changeUserStatus: changeUserStatusPermissions,
  bulkOnboard: bulkOnboardPermissions
};

export default usersPagePermissions;
