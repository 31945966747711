export const INTERNAL_SERVER_ERROR = 'Server Error';
export const UNAUTHORIZED = 'Unauthorized Access';

export const iamAlertCodes = {
  error: {
    LIST_ROLES: 'Error Listing Roles',
    UPDATE_ROLE: 'Error Updating Role',
    UPDATE_ROLE_UNAUTHORIZED: 'Insufficient Permissions To Update Role'
  },
  success: {
    UPDATE_ROLE: 'Role Updated Successfully'
  }
};

export const userAlertCodes = {
  error: {
    UNAUTHORIZED,
    LOGOUT: 'Logout Unsuccessful'
  }
};

export const onboardAlertCodes = {
  error: {
    DOC_TOKEN: 'Error Fetching Doc Token'
  }
};

export const userManagementAlertCodes = {
  error: {
    BULK_ONBOARD_INVALID_INPUT: 'Invalid Inputs, Please Check Them',
    BULK_ONBOARD_CONFLICT: 'One Or More Users Already Exist',
    BULK_ONBOARD: 'Error Inviting Users',
    LIST_USERS: 'Error Fetching Users',
    USER_STATUS: 'Error Changing User Status',
    USER_UNAUTHORIZED_STATUS: 'Insufficient Permissions To Change User Status',
    UPDATE_USER_UNAUTHORIZED: 'Insuficient permissions to update the user',
    UPDATE_USER: 'Error Updating User'
  },
  success: {
    BULK_ONBOARD_SUCCESS: 'Invited Users Successfully',
    BULK_ONBOARD_SUCCESS_PARTIALLY: 'Partially Invited Users Successfully',
    DEACTIVATE_USER_SUCCESS: 'User Deactivated Successfully',
    REACTIVATE_USER_SUCCESS: 'User Reactivated Successfully',
    UPDATE_USER: 'Updated User successfully'
  }
};
