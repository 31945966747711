import axios from 'axios';

const axiosRequest = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/api/v1/onboard`,
  withCredentials: true
});

const fetchDocTokenAPI = () => axiosRequest.get('/docToken');

export default fetchDocTokenAPI;
