/* eslint-disable no-param-reassign */
import React, { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

import './Popup.scss';

function handleOutsideViewport(element) {
  const rect = Object.assign(element.getBoundingClientRect());
  const leftExtended = rect.right - window.innerWidth;
  if (leftExtended > 0) element.style.left = `-${leftExtended}px`;
}

function CustomPopup({ children, position, isOpen }) {
  const popupRef = useRef(null);

  useEffect(() => {
    if (popupRef && popupRef.current) handleOutsideViewport(popupRef.current);
  }, [popupRef.current]);

  if (!isOpen) return '';

  return (
    <div id="custom_popup" className={position} ref={popupRef}>
      {children}
    </div>
  );
}

CustomPopup.defaultProps = {
  children: '',
  position: 'bottom'
};

CustomPopup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  position: PropTypes.string,
  isOpen: PropTypes.bool.isRequired
};

export default CustomPopup;
