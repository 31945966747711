import { difference } from 'lodash';

import { authorizedProductOption } from './constants';

const doesArrayHaveDuplicates = (arr) => arr.length !== new Set(arr).size;

export const getAuthorizedProductOption = (list) =>
  authorizedProductOption.filter((item) => list.includes(item.value));

export const getAddAndRemoveAuthorizedProduct = (selectedProducts, authorizedProducts) => {
  const addAuthorizedProducts = difference(selectedProducts, authorizedProducts);
  const removeAuthorizedProducts = difference(authorizedProducts, selectedProducts);
  return { addAuthorizedProducts, removeAuthorizedProducts };
};

export default doesArrayHaveDuplicates;
