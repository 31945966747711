import { get } from 'lodash';

import { authenticateUserAPI, getUserAPI, logoutAPI } from '../api/user';
import { userAlertCodes } from '../constants/alertCodes';
import { updateIsAuthenticated, updateUserInfo } from '../reducers/user';
import { persistor } from '../store';

export const authenticateUser =
  () =>
  async ({ dispatch, showErrorToast }) => {
    try {
      await authenticateUserAPI();
      dispatch(updateIsAuthenticated(true));
    } catch (error) {
      dispatch(updateIsAuthenticated(false));
      showErrorToast({ error, message: userAlertCodes.error.UNAUTHORIZED });
    }
  };

export const getUser =
  () =>
  async ({ dispatch, showErrorToast }) => {
    try {
      const res = await getUserAPI();
      dispatch(updateUserInfo(get(res, 'data.result', {})));
    } catch (error) {
      dispatch(updateIsAuthenticated(false));
      showErrorToast({ error, message: userAlertCodes.error.UNAUTHORIZED });
    }
  };

export const logout =
  () =>
  async ({ dispatch, showErrorToast }) => {
    try {
      await logoutAPI();
      await persistor.purge();
      dispatch({ type: 'RESET' });
      window.location.replace(`${process.env.REACT_APP_APPLICATION_DASHBOARD_URL}`);
    } catch (error) {
      showErrorToast({ error, message: userAlertCodes.error.LOGOUT });
    }
  };
