import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import OptionsButton from './OptionsButton';
import OptionsCell from './OptionsCell';
import CustomPopupContainer from '../../../../Common/Popup/CustomPopupContainer';

function Options({ isHoveredOn, name, email, role, status, authorizedProducts }) {
  const currentUserEmail = useSelector((state) => state.user.userDetails.email);

  if (currentUserEmail === email) {
    return '';
  }

  return (
    <CustomPopupContainer
      popupTrigger={<OptionsButton isHoveredOn={isHoveredOn} />}
      popupComponent={
        <OptionsCell
          name={name}
          email={email}
          role={role}
          status={status}
          authorizedProducts={authorizedProducts}
        />
      }
      popupPosition="bottom-left"
    />
  );
}

Options.propTypes = {
  isHoveredOn: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  status: PropTypes.bool.isRequired,
  authorizedProducts: PropTypes.array.isRequired
};

export default Options;
