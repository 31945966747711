import PropTypes from 'prop-types';

import DrawerWrapper from './DrawerWrapper';

function MainLayout({ children }) {
  return (
    <div id="main__container">
      <div id="main__container__navbar">
        <DrawerWrapper />
      </div>
      <div id="main__container__body">{children}</div>
    </div>
  );
}

MainLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export default MainLayout;
