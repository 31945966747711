import PropTypes from 'prop-types';

import './Modals.scss';

function OptionCell({ id, name, description }) {
  return (
    <div id={id} className="role_option_cell">
      <h2>{name}</h2>
      {description ? <p>{description}</p> : null}
    </div>
  );
}

OptionCell.defaultProps = {
  id: '',
  description: ''
};

OptionCell.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  description: PropTypes.string
};

export default OptionCell;
