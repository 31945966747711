import axios from 'axios';

const axiosRequest = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/api/v1/iam`,
  withCredentials: true
});

export const listRolesAPI = () => axiosRequest.get('/roles');

export const updateIAMRoleAPI = (data) => axiosRequest.put('/role', data);
