import React from 'react';

import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useDispatch, useSelector } from 'react-redux';

import { closeToast } from '../../reducers/toast';

function CustomAlert() {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.toast.show);
  const severity = useSelector((state) => state.toast.severity);
  const message = useSelector((state) => state.toast.message);

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(closeToast());
  };

  return (
    <Snackbar open={isOpen} onClose={handleAlertClose} id="alert-box">
      <Alert onClose={handleAlertClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export default CustomAlert;
