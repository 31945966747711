import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

function RoleCell({ iamRole }) {
  const rolesData = useSelector((state) => state.iam.rolesData);
  return get(rolesData, [iamRole, 'name'], iamRole);
}

RoleCell.propTypes = {
  iamRole: PropTypes.string.isRequired
};

export default RoleCell;
