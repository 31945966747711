import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modalId: '',
  inputData: null
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action) => {
      const { modalId, inputData } = action.payload;
      return { ...state, modalId, inputData };
    },
    closeModal: () => {
      return initialState;
    }
  }
});

// Action creators are generated for each case reducer function
export const { openModal, closeModal } = modalSlice.actions;
export default modalSlice.reducer;
