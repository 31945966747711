import React, { useState, useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

import CustomPopup from './CustomPopup';

import './Popup.scss';

function CustomPopupContainer({ popupTrigger, popupComponent, popupPosition }) {
  const containerRef = useRef(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleTriggerClick = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const handleMouseClick = (event) => {
    if (
      containerRef.current &&
      event.target !== containerRef.current &&
      !containerRef.current.contains(event.target)
    ) {
      closePopup();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseClick);
    return () => {
      document.removeEventListener('mousedown', handleMouseClick);
    };
  }, []);

  return (
    <div id="custom_popup_container" ref={containerRef}>
      <div id="custom_popup_trigger">
        {React.cloneElement(popupTrigger, {
          handleClick: handleTriggerClick,
          isActive: isPopupOpen
        })}
      </div>
      <CustomPopup position={popupPosition} isOpen={isPopupOpen}>
        {React.cloneElement(popupComponent, { handleClose: closePopup })}
      </CustomPopup>
    </div>
  );
}

CustomPopupContainer.defaultProps = {
  popupPosition: 'bottom'
};

CustomPopupContainer.propTypes = {
  popupTrigger: PropTypes.elementType.isRequired,
  popupComponent: PropTypes.elementType.isRequired,
  popupPosition: PropTypes.string
};

export default CustomPopupContainer;
