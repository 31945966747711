import { createSlice } from '@reduxjs/toolkit';

const initData = {
  data: [],
  count: 0
};

const initTableMeta = {
  page: 0,
  sortBy: {
    _id: 'desc'
  }
};

const initFilters = {
  searchValue: null
};

export const userManagementSlice = createSlice({
  name: 'userManagement',
  initialState: {
    data: initData.data,
    dataCount: initData.count,
    filters: initFilters,
    tableMeta: initTableMeta
  },
  reducers: {
    updateData: (state, action) => {
      const { data, count } = action.payload;
      return { ...state, data: data || initData.data, dataCount: count || initData.count };
    },
    updateTableMeta: (state, action) => {
      const updatedTableMeta = {};
      Object.entries(action.payload).forEach(([key, value]) => {
        updatedTableMeta[key] = value || initTableMeta[key];
      });
      return { ...state, tableMeta: { ...state.tableMeta, ...updatedTableMeta } };
    },
    updateRole: (state, action) => {
      const { email, role } = action.payload;
      const updateData = state.data.map((user) => {
        if (email === user.email) return { ...user, iamRole: role };
        return user;
      });
      return { ...state, data: updateData };
    },
    updateAuthorizedProduct: (state, action) => {
      const { email, authorizedProducts } = action.payload;
      const stateData = state?.data || [];
      const index = stateData.findIndex((obj) => obj?.email === email);
      if (index < 0) return state;
      const updatedUser = {
        ...stateData[index],
        authorizedProducts: [...authorizedProducts]
      };
      const updatedData = [...stateData];
      updatedData[index] = updatedUser;
      return { ...state, data: updatedData };
    },
    updateStatus: (state, action) => {
      const { email, status } = action.payload;
      const updateData = state.data.map((user) => {
        if (email === user.email) return { ...user, status };
        return user;
      });
      return { ...state, data: updateData };
    },
    updateFilters: (state, action) => {
      const updatedFilters = {};
      Object.entries(action.payload).forEach(([key, value]) => {
        updatedFilters[key] = value || initFilters[key];
      });
      return { ...state, filters: { ...state.filters, ...updatedFilters } };
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  updateData,
  updateTableMeta,
  updateRole,
  updateFilters,
  updateStatus,
  updateAuthorizedProduct
} = userManagementSlice.actions;
export default userManagementSlice.reducer;
