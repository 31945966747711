export const authorizedProductOption = [
  { label: 'Audit Portal', value: 'AuditPortal' },
  { label: 'Video KYC', value: 'VKYCPortal' }
];

export const DEFAULT_AUTHORIZED_PRODUCT = [authorizedProductOption[0]];

export const PERFORMANCE_METRIC_EVENTS = {
  USER_PAGE_INIT_FETCH: 'USER_PAGE_INIT_FETCH',
  USER_PAGE_LIST_USERS: 'USER_PAGE_LIST_USERS',
  USER_PAGE_INVITE_USERS: 'USER_PAGE_INVITE_USERS',
  USER_PAGE_EDIT_USER: 'USER_PAGE_EDIT_USER',
  USER_PAGE_UPDATE_STATUS: 'USER_PAGE_UPDATE_STATUS'
};
