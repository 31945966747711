import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { PermissionWrapper } from 'storybook-ui-components';

import { ReactComponent as InviteIcon } from '../../../assets/icons/invite.svg';
import analyticsEvents from '../../../constants/analyticsEvents';
import useGetUserPermissions from '../../../Permissions/hooks';
import getPermission from '../../../Permissions/mapping';
import { openModal } from '../../../reducers/modal';
import { useFormatAnalyticsData } from '../../../utils/customHooks';
import CustomButton from '../../Common/Buttons/CustomButton';

function InviteUsersButton() {
  const dispatch = useDispatch();
  const { email, clientId } = useSelector((state) => state.user.userDetails);
  const formatAnalyticsData = useFormatAnalyticsData();

  const inviteUsersOnClickHandler = () => {
    formatAnalyticsData(email, clientId, analyticsEvents.DASHBOARD_USER_INVITE_USER_CLICK, 'User');
    dispatch(
      openModal({
        modalId: 'inviteUsers',
        inputData: {}
      })
    );
  };

  return (
    <CustomButton IconLeft={InviteIcon} theme="purple" onClick={inviteUsersOnClickHandler}>
      Invite Members
    </CustomButton>
  );
}

export default PermissionWrapper(
  InviteUsersButton,
  useGetUserPermissions,
  getPermission('bulkOnboard')
);
