import React from 'react';

import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';

import { logout } from '../actions/user';
import CustomConnect from '../containers/HOC/CustomConnect';

class ErrorBoundary extends React.Component {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
    Sentry.captureException(error);
  }

  render() {
    const { hasError } = this.state;
    const { children, logout } = this.props;
    if (hasError) {
      logout();
      return <h2>Something went wrong. Please wait while were refresh</h2>;
    }
    return children;
  }
}

const mapHooksToActions = {
  logout
};

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  logout: PropTypes.func.isRequired
};

export default CustomConnect(ErrorBoundary, { mapHooksToActions });
