import { CommonTable } from 'clm-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { genTableData, tableHeaders } from './UserTableUtils';
import userListTableConfig from '../../../../constants/config';

import './UserListTable.scss';

const { maxRowsPerPage } = userListTableConfig;

function UserTable({ isLoading, handlePageChange }) {
  const usersData = useSelector((state) => state.userManagement.data);
  const total = useSelector((state) => state.userManagement.dataCount);
  const tableMeta = useSelector((state) => state.userManagement.tableMeta);
  const { page } = tableMeta || {};
  const userDetails = genTableData(usersData);

  return (
    <CommonTable
      tableHeader={tableHeaders}
      tableData={userDetails}
      isLoading={isLoading}
      id="user_management__table__container"
      emptyStateMessage="No Users yet"
      pageNumber={page}
      pageLength={maxRowsPerPage}
      totalRows={total}
      handlePageChange={handlePageChange}
    />
  );
}

UserTable.propTypes = {
  handlePageChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default UserTable;
