import PropTypes from 'prop-types';

import AddToolTipOnDisable from '../AddToolTipOnDisable';

import './Buttons.scss';

const disabledOnClick = () => {};

function CustomButton({
  id,
  theme,
  disabled,
  disabledToolTip,
  children,
  IconLeft,
  IconRight,
  onClick
}) {
  return (
    <AddToolTipOnDisable isDisabled={disabled && disabledToolTip}>
      <button
        id={id}
        type="button"
        className={`custom_button ${theme} ${disabled ? 'disabled' : ''}`}
        onClick={disabled ? disabledOnClick : onClick}>
        {IconLeft && <IconLeft className="icon_left" />}
        {children}
        {IconRight && <IconRight className="icon_right" />}
      </button>
    </AddToolTipOnDisable>
  );
}

CustomButton.defaultProps = {
  id: '',
  theme: 'white',
  disabled: false,
  disabledToolTip: false,
  IconLeft: null,
  IconRight: null,
  onClick: disabledOnClick
};

CustomButton.propTypes = {
  id: PropTypes.string,
  theme: PropTypes.string,
  disabled: PropTypes.bool,
  disabledToolTip: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  IconLeft: PropTypes.element,
  IconRight: PropTypes.element,
  onClick: PropTypes.func
};

export default CustomButton;
